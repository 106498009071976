.skill-category {
    text-align: center;
}

.category-title {
    font-size: 2em;
}

.skill-list {
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center; 
}

.skill-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.svg-icon {
    width: auto; 
    height: 3rem;
    margin-bottom: 3px;
}

.skill-name {
    font-size: 1em;
}

@media screen and (min-width: 800px) {
    .skill-list {
        grid-template-columns: repeat(2, 1fr);
    }
}