.about-container {
    position: relative; 
    height: calc(100vh - 94.75px);
    width: 100vw;
    max-width: 100vw;
    z-index: 1;
}

.about-content {
    width: 80vw;
    margin-left: 0%;
    margin-top: 45%;    
    overflow-y: auto; 
    overflow-x: hidden;
    z-index: 2;
    position: relative;
}

.about-content::-webkit-scrollbar {
    display: none;
}

.card {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
}

.glow {
    text-shadow: 0px 0px 10px #e98531;
}

.fw-800 {
    font-weight: 800;
}

@media screen and (min-height: 700px) and (max-width: 801px) {
    .about-content {
        margin-top: 53%;
    }
}

@media screen and (min-width: 801px) {
    .about-content {
        width: 45vw;
        margin-left: 35%;
    }
}
  
@media screen and (min-width: 750px) and (min-height: 1023px){
    .about-content {
        width: 80vw;
        margin-left: 0%;
    }
}

@media screen and (min-width: 1023px) and (min-height: 599px){
    .about-content {
        margin-top: 10%;
        width: 60vw;
        margin-left: 35%;
    }
}

@media screen and (min-width: 1281px) {
    .about-content {
        margin-top: 5%;
        width: 45vw;
        margin-left: 35%;
    }
}

@media screen and (min-width: 1921px) {
    .about-content {
        margin-top: 10%;
    }
}