.small-code {
    line-height: 1.2;  
    padding: 0.5rem;
    font-size: 0.65rem;
}

.hljs {
    background-color: rgba(0, 0, 0, 0.514);
}

.gif {
    width: 15rem;
}

.boids {
    width: 100%;
}

@media screen and (min-width: 1280px) {
    .boids {
        width: 40rem;
    }
}


@media screen and (min-width: 1921px) {
    .gif  {
        width: 25rem;
    }

    .small-code {
        font-size: 0.75rem;
    }
}