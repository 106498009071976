.w-30  {
    width: 85vw;
}

.opacity-80 {
    opacity: 80%;
}

@media screen and (max-width: 800px) and (min-height: 700px)  {
    .w-30  {
        width: 85vw;
    }

    .mt {
        margin-top: 20rem;
    }
}

@media screen and (min-width: 801px) {
    .w-30  {
        width: 80vw;
    }

    .mt {
        margin-top: 30rem;
    }
}

@media screen and (min-width: 1023px) and (min-height: 1023px){
    .w-30  {
        width: 80vw;
    }

    .mt {
        margin-top: 35rem;
    }
}


@media screen and (min-width: 1023px) and (max-height: 1022px){
    .w-30  {
        width: 40vw;
    }

    .mt {
        margin-top: 10rem;
    }
}


@media screen and (min-width: 1201px) {
    .w-30  {
        width: 35vw;
    }

    .mt {
        margin-top: 23rem;
    }
}

@media screen and (min-width: 1921px) {
    .w-30  {
        width: 35vw;
    }

    .mt {
        margin-top: 33rem;
    }
}