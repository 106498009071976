::-webkit-scrollbar {
    width: 5px;  
    height: 5px; 
}

::-webkit-scrollbar-track {
    background: #000000; 
    border-radius: 10px; 
}

::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 10px; 
    transition: background 0.3s ease; 
}

::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

::-webkit-scrollbar-thumb:active {
    background: #333; 
}

.scrollbar {
    scrollbar-width: thin; 
    scrollbar-color: #888 #000000;
}

html {
    scroll-behavior: smooth; 
}