.nav-link {
    transition: all 0.1s ease;
}

.nav-link:hover{
    font-weight: bold;
    transform: scale(1.1);
}

.bg-body-tertiary {
    background-color: transparent !important;
}

.brand-size {
    width: 5rem;
    height: auto;
}