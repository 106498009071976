.thumbnail {
    width: 100%; 
    border-radius: 8px; 
    transition: transform 0.3s ease; 
}

.thumbnail:hover {
    transform: scale(1.05);
}

.modal-container .modal-dialog {
    max-width: 70vw; 
}

.modal-image {
    width: 100%; 
    max-height: 90vh;
    object-fit: contain; 
}

@media screen and (min-width: 1280px) {
    .thumbnail {
        max-height: 400px;
    }
}

