body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-transparent {
  background-color: transparent !important;
}

.blur {
  backdrop-filter: blur(5px);
}

.mt-nav {
  margin-top: 94.75px;
}

.orange-text {
  color: #e98531 !important;
}

.light-blue-text {
  color:  rgb(160, 233, 255) !important;
}

.content {
  position: relative;
  z-index: 1;
  width: 100%;
}

.max-height {
  max-height: calc(100vh - 94.75px);
}

.height {
  height: calc(100vh - 94.75px);  
}

.min-height {
  min-height: calc(100vh - 94.75px);
}

.radius-20 {
  border-radius: 20px;
}