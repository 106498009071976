.button {
    cursor: pointer;
    position: relative;
    padding: 10px 24px;
    font-size: 18px;
    color:  rgb(160, 233, 255);;
    border: 2px solid  rgb(160, 233, 255);;
    border-radius: 34px;
    background-color: rgba(0, 44, 58, 0.65);
    font-weight: 600;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
    overflow: hidden;
}
  
.button::before {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    width: 100px;
    height: 100px;
    border-radius: inherit;
    scale: 0;
    z-index: -1;
    background-color: rgb(160, 233, 255);
    transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}
  
.button:hover::before {
    scale: 3;
}
  
.button:hover {
    color: #212121;
    scale: 1.1;
    box-shadow: 0 0px 20px  rgba(160, 233, 255, 0.521);
}
  
.button:active {
    scale: 1;
}