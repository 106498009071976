.astronaut-container {
    position: fixed; 
    height: calc(100vh - 94.75px);
    width: 100%;
    z-index: 1;
}
  
.astronaut-container img {
    position: relative; 
    top: -5%;
    left: -30%;
    animation: float 5s ease-in-out infinite;
    width: 40rem;
}

@media screen and (max-width: 800px) and (min-height: 700px)  {
  .astronaut-container img {
      top: 0%;
      left: -20%;
  }
}

@media screen and (min-width: 801px) {
  .astronaut-container img {
      top: 15%;
      left: 0%;
      width: 55rem;
  }
}

@media screen and (min-width: 750px) and (min-height: 1023px){
  .astronaut-container img {
      top: 0%;
      left: -20%;
      width: 70rem;
  }
}

@media screen and (min-width: 1023px) and (min-height: 1023px){
  .astronaut-container img {
      top: 0%;
      left: -17.5%;
      width: 90rem;
  }
}

@media screen and (min-width: 1023px) and (min-height: 599px){
  .astronaut-container img {
      top: 10%;
      left: -5%;
      width: 40rem;
  }
}

@media screen and (min-width: 1201px) {
  .astronaut-container img {
      top: 15%;
      left: 0%;
      width: 55rem;
  }
}

@media screen and (min-width: 1921px) {
  .astronaut-container img {
      left: 10%;
  }
}
  
@keyframes float {
    0% {
      transform: rotate(-1deg);
    }
    50% {
      transform: rotate(3deg);
    }
    100% {
      transform: rotate(-1deg);
    }
}
  